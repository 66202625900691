import React from "react";
import PropTypes from "prop-types";

import Navigation from "../navigation/navigation";

/**
 * LinksDataObject
 * @typedef {Object} LinksDataObject
 * @property {string} id - ID like "nav-...".
 * @property {string} href - The URL of the link.
 * @property {string} title - Title to show the link.
 */

/**
 * This is the component to render the Navigation section with its links.
 *
 * @param {Object} props - The object containing props.
 * @param {string} props.sectionClass - By default we use "bg-light py-3 mt-3".
 * @param {LinksDataObject[]} props.linksData - An array of objects with 3 properties.
 *   - `id`: ID like "nav-...".
 *   - `href`: The URL of the link.
 *   - `title`: Title to show the link.
 * @returns {React.JSX.Element} Returns the JSX component to render
 */
const AboutUsNavigation = ({
  sectionClass = "bg-light py-3 mt-3",
  linksData = [
    {
      id: "nav-community-relations",
      href: "/about-us/community-giving",
      title: "Community Relations"
    },
    {
      id: "nav-community-financial-education",
      href: "/about-us/community-financial-education",
      title: "Financial Literacy"
    },
    {
      id: "nav-washington-federal-foundation",
      href: "/about-us/washington-federal-foundation",
      title: "Washington Federal Foundation"
    },
    {
      id: "nav-wafd-bank-community-and-social-responsibility",
      href: "/about-us/investor-relations/csr",
      title: "Corporate and Social Responsibility"
    },
    {
      id: "nav-community-meeting-rooms",
      href: "/about-us/community-meeting-rooms",
      title: "Community Meeting Rooms"
    },
    {
      id: "nav-community-reinvestment-act",
      href: "/about-us/community-reinvestment-act",
      title: "Community Reinvestment Act"
    }
  ]
}) => {
  return <Navigation {...{ sectionClass, linksData }} />;
};

export default AboutUsNavigation;

AboutUsNavigation.propTypes = {
  sectionClass: PropTypes.string,
  linksData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    })
  )
};
