import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import Icon from "../custom-widgets/icon";

import "../../pages/about-us/investor-relations/csr.bootstrap.scss";

const Navigation = (props) => {
  let path = useLocation().pathname;
  const isActiveLink = function (url) {
    if (path.substring(path.length - 1, path.length - 1) === "/") {
      path = path.substring(0, path.length - 1);
    }
    return path === url;
  };

  return (
    <section className={props.sectionClass} id="navigation-component">
      <div className="container">
        <div className="row no-gutter">
          {props.linksData.map((link, index) => (
            <div className={`my-2 col-sm-6 col-md-4`} key={index}>
              <Link
                id={link.id}
                className={`csr-nav-link text-gray-70 d-flex text-decoration-none d-flex align-items-baseline ${
                  isActiveLink(link.href) ? "csr-nav-active-tab" : ""
                }`}
                to={link.href}
              >
                <Icon name={link.icon ? link.icon : "arrow-right"} class="mr-2" />
                {link.title}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Navigation;

Navigation.defaultProps = {
  sectionClass: "bg-light py-3",
  linksData: [
    {
      id: "",
      title: "",
      href: ""
    }
  ]
};
