import React from "react";
import { Link } from "gatsby";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import AboutUsNavigation from "../../components/about-us/about-us-navigation";

const CommunityReinvestmentAct = () => {
  const title = "Community Reinvestment Act";
  const description = "Community Reinvestment Act (CRA) information and documentation";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/community-reinvestment-act"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      active: true,
      title: "Community Reinvestment Act"
    }
  ];

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <AboutUsNavigation />
      <section className="container pb-0">
        <h1>Community Reinvestment Act</h1>
        <h4>
          WaFd Bank is pleased to provide information for public inspection under the Community Reinvestment Act (CRA).
          Interested parties can review the data provided and produce hard copies of documents as needed.
        </h4>
        <h4 className="mb-0">
          Under the CRA, the following information must be maintained in a public file, current as of April 1 of each
          year.
        </h4>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <ul className="mb-0">
              <li>
                A copy of the Public Evaluation of the bank's most recent{" "}
                <a
                  id="cra-performance-evaluation-pdf-link"
                  href="/documents/cra-performance-evaluation.pdf"
                  target="_blank"
                >
                  CRA Performance Evaluation (PDF)
                </a>
              </li>
              <li>
                <a id="branch-locations-pdf-link" href="/documents/branch-locations.pdf" target="_blank">
                  A list of branch locations (PDF)
                </a>{" "}
                including hours of operation.
              </li>
              <li>
                <a
                  id="opened-or-closed-branches-pdf-link"
                  href="/documents/opened-or-closed-branches.pdf"
                  target="_blank"
                >
                  Branches opened or closed (PDF)
                </a>{" "}
                during the current year and each of the prior two calendar years.
              </li>
              <li>
                <a id="products-and-services-pdf-link" href="/documents/products-and-services.pdf" target="_blank">
                  Services available (PDF)
                </a>{" "}
                at the bank's branches including automated teller machines (ATMs). Questions regarding transaction fees
                or cost of services should be directed to your nearest{" "}
                <Link to="/locations" id="locations-page-link">
                  WaFd Bank location
                </Link>
              </li>
              <li>
                <a id="assessment-areas-all-pdf-link" href="/documents/assessment-areas-all.pdf" target="_blank">
                  AA map and tract listing (PDF)
                </a>{" "}
                for each assessment area designated by the bank.
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <ul>
              <li>
                <a
                  id="2021-disclosure-statement-pdf-link"
                  href="/documents/2021-disclosure-statement.pdf"
                  target="_blank"
                >
                  The bank's CRA Disclosure Statement (PDF) for 2021
                </a>
              </li>
              <li>
                <a
                  id="2022-disclosure-statement-pdf-link"
                  href="/documents/2022-disclosure-statement.pdf"
                  target="_blank"
                >
                  The bank's CRA Disclosure Statement (PDF) for 2022
                </a>
              </li>
              <li>
                The bank's{" "}
                <a
                  id="home-mortgage-disclosure-act-notice-pdf-link"
                  href="/documents/home-mortgage-disclosure-act-notice.pdf"
                  target="_blank"
                >
                  HMDA Disclosure Statement (PDF)
                </a>
                .
              </li>
              <li>
                <a id="complaints-all-pdf-link" href="/documents/complaints-all.pdf" target="_blank">
                  All public comments (PDF)
                </a>{" "}
                received for the current year and prior two calendar years.{" "}
              </li>
            </ul>

            <p>
              Any comments or questions about this portion of the WaFd Bank website or about WaFd Bank's CRA performance
              may be emailed to{" "}
              <a id="cra-wafd-email-link" href="mailto:CRA@wafd.com">
                CRA@wafd.com
              </a>{" "}
              , or addressed to:
            </p>
            <p className="mb-0">
              <strong>Community Reinvestment Act Program Management</strong>
              <br />
              425 Pike Street
              <br />
              Seattle, WA 98101
            </p>
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default CommunityReinvestmentAct;
